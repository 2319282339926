/*
 * APNIC Navbar toggling
 */
(() => {
    // Toggle shadowbox
    const showShadowbox = (boolean) => {
        if (boolean === true) {
            jQuery('#apnic-navbar-content >.shadowbox')
                .removeClass('fade-out');

            jQuery('#apnic-navbar-content > .shadowbox')
                .addClass('d-block fade-in');
        } else if (boolean === false) {
            jQuery('#apnic-navbar-content > .shadowbox')
                .removeClass('fade-in');

            jQuery('#apnic-navbar-content > .shadowbox')
                .addClass('fade-out');

            setTimeout(() => {
                jQuery('#apnic-navbar-content >.shadowbox')
                    .removeClass('d-block fade-out');
            }, 400);
        }
    };

    // Menu booleans
    let isSidebarVisible = false;
    let isSearchbarVisible = false;

    const removeSidebar = () => {
        jQuery('#apnic-navbar-content > .container')
            .removeClass('menu-slide-left');

        jQuery('#apnic-navbar-content > .container')
            .addClass('menu-slide-right');

        setTimeout(() => {
            jQuery('#apnic-navbar-content > .container')
                .removeClass('d-block menu-side-right');
        }, 400);
    };

    const removeSearch = () => {
        jQuery('#apnic-nav-search-content').removeClass('fade-in');
        jQuery('#apnic-nav-search-content').addClass('fade-out');
        jQuery('#apnic-nav-search-content > ul > li.nav-item:nth-child(2) > a')
            .removeClass('btn btn-primary');
        jQuery('#apnic-nav-search-content > ul > li.nav-item:nth-child(2) > a')
            .addClass('nav-link');
        setTimeout(() => {
            jQuery('#apnic-nav-search-content')
                .removeClass('d-block fade-out');
        }, 400);
    };

    // Toggle sidebar nav
    jQuery('#apnic-navbar-toggler').on('click', () => {
        if (isSidebarVisible === false) {
            if (isSearchbarVisible === true) {
                removeSearch();
                isSearchbarVisible = false;
            }

            jQuery('#apnic-navbar-content > .container')
                .removeClass('menu-slide-right');

            jQuery('#apnic-navbar-content > .container')
                .addClass('d-block menu-slide-left');

            showShadowbox(true);

            isSidebarVisible = true;
        } else if (isSidebarVisible === true) {
            removeSidebar();
            showShadowbox(false);
            isSidebarVisible = false;
        }
    });

    // Toggle Search
    jQuery('#apnic-search-toggler').on('click', () => {
        if (isSearchbarVisible === false) {
            if (isSidebarVisible === true) {
                removeSidebar();
                isSidebarVisible = false;
            }
            jQuery('#apnic-nav-search-content').removeClass('fade-in');
            jQuery('#apnic-nav-search-content').addClass('d-block fade-in');
            jQuery('#apnic-nav-search-content > ul > li.nav-item:nth-child(2) > a')
                .removeClass('nav-link');
            jQuery('#apnic-nav-search-content > ul > li.nav-item:nth-child(2) > a')
                .addClass('btn btn-primary');
            showShadowbox(true);
            isSearchbarVisible = true;
        } else if (isSearchbarVisible === true) {
            removeSearch();
            showShadowbox(false);
            isSearchbarVisible = false;
        }
    });
})();
