/*
 * APNIC Application Sidebar
 */

$(document).ready(() => {
    let isMobile = $(window).width() < 767;
    // Remove toggle class on mobile (to hide sidebar on small devices on page reload)
    if (isMobile) {
        $('#wrapper').removeClass('toggled');
    }

    $('#menu-toggle').click((e) => {
        e.preventDefault();
        // Toggle sidebar
        $('#wrapper').toggleClass('toggled');
        // Show sidebar on mobile once toggled class is added
        $('#sidebar-wrapper').removeClass('d-none');
        $('#sidebar-wrapper').addClass('d-flex');
    });

    $(window).resize(() => {
        const width = $(window).width();

        // If expanding from a small screen width to larger screen width
        if (isMobile) {
            if (width > 767) {
                // show the sidebar
                $('#wrapper').addClass('toggled');
            }
            isMobile = false;
        } else {
            // else hide the side bar
            if (width < 767) {
                $('#wrapper').removeClass('toggled');
            }
            isMobile = true;
        }
    });
});
